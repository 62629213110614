import React, { useState } from "react";
import { Modal, Button, Spinner } from "react-bootstrap";
import { useAuthContext } from "../../hooks/useAuthContext";
import { toast } from 'react-toastify'
import Papa from "papaparse";
import { Link } from "react-router-dom";
export default function BulkUpdate(props) {
	const { user } = useAuthContext()
	const { setUpdatemodal, categoryname, collection_id } = props
	const [csvdata, setCsvdata] = useState([])
	const [loader, setLoader] = useState(false)


	const handleClose = () => {
		setUpdatemodal(false)
	}
	const handleFileUpload = async (csv_file) => {
		setLoader(true)
		await Papa.parse(csv_file[0], {
			header: true,
			skipEmptyLines: true,
			complete: function (results) {
				const valuesArray = {};
				// Iterating data to get column name and their values

				results.data.map((d) => {
					const attributes_data = []
					const eachrow = {}

					let colname = Object.keys(d)
					let val = Object.values(d)
					colname.map((item, index) => {

						if (item.includes('trait_type')) {
							const a = {}
							let trait_name = (item.split('|'))
							a['trait_type'] = trait_name[1]
							a['value'] = val[index]
							attributes_data.push(a)
						}
						else {
							eachrow[item] = val[index]
						}


					})
					eachrow['attributes'] = attributes_data
					let token_name = d['name']



					valuesArray[token_name] = eachrow;
				});
				setCsvdata(valuesArray)
			},
		});
		setLoader(false)
	}

	const handleSubmit = async (e) => {
		e.preventDefault()

		const data = { categoryname, collection_id, csvdata }
		try {
			setLoader(true)
			var size = Object.keys(csvdata).length;
			console.log(size)
			const response = await fetch(process.env.REACT_APP_BASE_URL + '/api/collections/bulkupdate', {
				method: 'POST',
				body: JSON.stringify(data),
				headers: {
					'Content-Type': 'application/json',
					'Authorization': `Bearer ${user.token}`
				}
			})
			const json = await response.json()
			if (!response.ok) {
				toast.error(json.message);
			}
			if (response.ok) {
				setUpdatemodal(false)
				toast.success(json.message);
				// setTimeout((window.location.reload()), 4000)

			}
		}
		catch (e) {
			console.log(e)
			toast.error("An error occurred");
		}
		finally {
			setLoader(false)
		}
	}
	return (
		<Modal show={true} onHide={handleClose}>
			<Modal.Header closeButton>
				<Modal.Title>Bulk Update Metadata</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<span>Collection Name:</span> <span className="font-weight-bold">{categoryname}</span>
				<div>Sample file <Link to="/Item_Master_Format.csv" target="_blank" download>Download</Link></div>
				<label>Select CSV</label>
				{/* <CSVReader parserOptions={papaparseOptions} onFileLoaded={(data) => handleFileUpload(data)} /> */}
				<input type="file" id="file" multiple name="file" onChange={e => handleFileUpload(e.target.files)} />
			</Modal.Body>
			<Modal.Footer>
				<Button variant="secondary" onClick={handleClose}>
					Close
				</Button>
				{loader ? <Spinner variant="primary" animation="border" /> :
					<Button variant="primary" onClick={handleSubmit}>
						Upload
					</Button>
				}
			</Modal.Footer>
		</Modal>
	)
}