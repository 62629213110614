import { useEffect, useState } from 'react'
import { useAuthContext } from "../hooks/useAuthContext"

// components
import CategoryDetails from '../components/CategoryDetails'
import CategoryForm from '../components/CategoryForm'

const Home = () => {
  const [allCategories, setallCategories] = useState('')
  const { user } = useAuthContext()
  useEffect(() => {
    const fetchcategories = async () => {
      const response = await fetch(process.env.REACT_APP_BASE_URL + '/api/collections/', {
        headers: { 'Authorization': `Bearer ${user.token}` },
      })
      const json = await response.json()
      if (response.ok) {
        setallCategories(json.Collection_List)
      }
    }

    if (user) {
      fetchcategories()
    }
  }, [user])

  return (
    <div className="home">

      {allCategories !== '' ?
        <div className="workouts">
          <CategoryDetails key={allCategories._id} category={allCategories} />
        </div>

        : <CategoryForm />}
    </div>
  )
}

export default Home