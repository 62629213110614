import React, { useState, useEffect } from "react"
import { useParams, Link } from 'react-router-dom'
import MUIDataTable from 'mui-datatables'
import { useAuthContext } from '../../hooks/useAuthContext'
import { useNavigate } from 'react-router-dom';
import { toast } from "react-toastify";
import NewMetaData from "./NewMetaData";
import { Button } from "react-bootstrap";
import BulkUpdate from "./BulkUpdate";
const Metadata = () => {
	let navigate = useNavigate();

	const { id } = useParams();
	const [allmetadata, setAllmetadata] = useState([])
	const { user } = useAuthContext()
	const [loader, setLoader] = useState(false)
	const [page, setPage] = useState(0)
	const [count, setCount] = useState(0)
	const [deleterows, setDeleterows] = useState([])
	const [addnewMeta, setAddnewMeta] = useState(false)
	const [updatemodal, setUpdatemodal] = useState(false)
	useEffect(() => {
		setLoader(true)

		if (user) {
			fetchmetadata(0)
		}
	}, [user])

	const fetchmetadata = async (pagenumber) => {
		try {
			const response = await fetch(process.env.REACT_APP_BASE_URL + '/api/collections/all/' + id + '/' + pagenumber, {
				headers: { 'Authorization': `Bearer ${user.token}` },
			})
			const data = await response.json()
			setLoader(false)
			if (response.ok) {
				setAllmetadata(data.Collection_List)
				setCount(data.totalRecord)
				setPage(pagenumber)
			}

		} catch (e) {
			console.log(e)
		} finally {
			setLoader(false);
		}


	}
	function handleClick(i) {
		const data = allmetadata[i]
		navigate('/editmetadata', {

			state: data

		})
	}
	const options = {
		selectableRowsHideCheckboxes: false,
		filter: false, // show the filter icon in the toolbar (true by default)
		search: true,
		print: false,
		download: false,
		viewColumns: false,
		selectableRowsOnClick: true,
		filterType: 'dropdown',
		responsive: 'standard',
		page: page,
		count: count,
		Total: count,
		serverSide: true,
		rowsPerPage: 10,
		rowsPerPageOptions: [10],
		onRowSelectionChange: (rowsSelectedData, allRows, rowsSelected) => {
			setDeleterows(rowsSelected)
		},
		onRowsDelete: (rowsDeleted) => {
			deletemetadata()


		},

		onTableChange: (action, tableState) => {

			switch (action) {
				case 'changePage':
					changePage(tableState.page, tableState.sortOrder)
					break
				case 'search':
					handlesearch(tableState.searchText, tableState.page)
					break
				case 'onSearchClose':
					fetchmetadata(0)
				default:
			}
		},
	}
	const handlesearch = async (searchtext, currentPage) => {
		try {
			setLoader(true)
			const search_data = { searchtext, currentPage }
			const response = await fetch(process.env.REACT_APP_BASE_URL + '/api/collections/search/', {
				headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${user.token}` },
				method: 'POST',

				body: JSON.stringify(search_data),
			})
			const data = await response.json()
			if (response.ok) {
				setAllmetadata(data.Collection_List)
				setCount(data.totalRecord)
				setPage(0)
			}

		} catch (e) {
			console.log(e)
		} finally {
			setLoader(false);
		}
	}
	const deletemetadata = async () => {
		var selectedrows = [];
		deleterows.map((i, index) => (
			selectedrows[index] = allmetadata[i]._id
		))

		try {
			const response = await fetch(process.env.REACT_APP_BASE_URL + '/api/collections/delete', {
				headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${user.token}` },
				method: 'POST',
				body: JSON.stringify({ selectedrows })
			})
			const data = await response.json()
			setLoader(false)
			if (response.ok) {
				toast.success('All Data successfully deleted')


			}
			else {
				toast.error(data.message)
			}

		} catch (e) {
			toast.error('An error occurred')
			console.log(e)
		} finally {
			setLoader(false);
		}
	}
	const changePage = async (page_no, sortOrder) => {
		setLoader(true)
		fetchmetadata(page_no)
	}
	const columns = [
		{
			name: "name",
			label: "Name",
			options: {
				filter: true,
				sort: false,
			}
		},
		{
			name: "metadata",
			label: "Metadata",
			options: {
				filter: true,
				sort: false,
			}
		},
		{
			name: 'Actions',
			options: {
				filter: false,
				sort: false,
				empty: true,
				setCellProps: () => ({ style: { verticalAlign: 'middle' } }),
				customBodyRender: (value, tableMeta) => {
					return (<span onClick={() => handleClick(tableMeta.rowIndex)} className="material-symbols-outlined" >visibility</span>)
				}
			}
		}
	];
	return (<>
		{addnewMeta && <NewMetaData collection_id={allmetadata[0].CollectionId} categoryname={allmetadata[0].CategoryName} setAddnewMeta={setAddnewMeta} />}
		{updatemodal && <BulkUpdate collection_id={allmetadata[0].CollectionId} categoryname={allmetadata[0].CategoryName} setUpdatemodal={setUpdatemodal} />}
		<div className="btn-toolbar justify-content-between" role="toolbar" aria-label="Toolbar with button groups">
			<div className="btn-group" role="group" aria-label="First group">
				<Button variant="primary" onClick={() => setAddnewMeta(!addnewMeta)}>Add new</Button>
			</div>
			<div className="input-group">
				<div className="input-group-prepend">
					<Button className="float-right" variant="primary" onClick={() => setUpdatemodal(!updatemodal)}>Update Bulk Data</Button>
				</div>
			</div>
		</div>
		<br />
		<MUIDataTable
			title={
				<div>
					Metadata List
					{loader ? <div className="lds-dual-ring" ></div> : ''}
				</div>
			}
			className="table table-bordered dt-responsive nowrap"
			data={allmetadata}
			columns={columns}
			options={options}
		/>

	</>
	)
}
export default Metadata