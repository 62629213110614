import { useState } from "react"
import { useAuthContext } from '../hooks/useAuthContext'
import { toast } from "react-toastify";
const CategoryForm = () => {
  const { user } = useAuthContext()

  const [categoryname, setCategoryName] = useState('')
  const [description, setDescription] = useState('')
  const [error, setError] = useState(null)
  const [emptyFields, setEmptyFields] = useState([])
  const [uploadedFiles, setUploadedFiles] = useState({})
  const [loader, setLoader] = useState(false)
  const handleSubmit = async (e) => {
    e.preventDefault()
    setLoader(true)
    if (!user) {
      setError('You must be logged in')
      return
    }

    const category_data = { categoryname, description, uploadedFiles }
    try {
      var size = Object.keys(uploadedFiles).length;
      console.log(size)
      const response = await fetch(process.env.REACT_APP_BASE_URL + '/api/collections/create', {
        method: 'POST',
        body: JSON.stringify(category_data),
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${user.token}`
        }
      })
      const json = await response.json()
      setLoader(false)
      if (!response.ok) {
        setError(json.error)
        setEmptyFields(json.emptyFields)
      }
      if (response.ok) {
        setCategoryName('')
        setDescription('')
        setUploadedFiles('')
        setError(null)
        setEmptyFields([])
        toast.success("Collection created successfully");
        setTimeout((window.location.reload()), 2000)

      }
    }
    catch (e) {
      console.log(e)
      toast.error("An error occurred");
    }
    finally {
      setLoader(false)
    }
  }
  const handleFileUpload = async (files) => {
    setLoader(true)
    var obj = {}

    for (let i = 0; i < files.length; i++) {
      var fileReader = new FileReader();
      await fileReader.readAsText(files[i], "UTF-8");
      fileReader.onload = e => {
        let filename = files[i].name.split('.')
        filename = filename[0]
        obj[filename] = JSON.parse(e.target.result)

      };
    }
    setUploadedFiles(obj)
    setLoader(false)

  }
  return (
    <form className="create" onSubmit={handleSubmit}>
      <h3>Add a New Collection</h3>

      <label>Title:</label>
      <input
        type="text"
        onChange={(e) => setCategoryName(e.target.value)}
        value={categoryname}
        className={emptyFields.includes('categoryname') ? 'error' : ''}
      />

      <label>Description</label>
      <input
        type="text"
        onChange={(e) => setDescription(e.target.value)}
        value={description}
        className={emptyFields.includes('description') ? 'error' : ''}
      />

      <label>Collection json</label>
      <input type="file" id="file" multiple name="file" onChange={e => handleFileUpload(e.target.files)} />


      <div className="create">
        {loader ? <div className="loader"></div> :
          <button className="button-save">Add Collection</button>}
      </div>
      {error && <div className="error">{error}</div>}
    </form>
  )
}

export default CategoryForm