import React, { useState } from "react";
import { useLocation } from 'react-router-dom'
import { useAuthContext } from "../../hooks/useAuthContext";
import JsonEditor from 'react-json-editor-ui'
import { toast } from 'react-toastify'
import 'react-json-editor-ui/dist/react-json-editor-ui.cjs.development.css'
export default function EditMetaData() {
	const [loader, setLoader] = useState(false)
	const { state } = useLocation();
	const { metadata, _id, name, CategoryName } = state
	const [editObject, setEditObject] = React.useState(JSON.parse(metadata))
	const { user } = useAuthContext()
	const handleupdate = async () => {
		setLoader(true)
		const data = {
			metadata: editObject
		}
		try {
			const response = await fetch(process.env.REACT_APP_BASE_URL + '/api/collections/' + _id, {
				method: 'PUT',
				body: JSON.stringify(data),

				headers: {
					"Content-Type": "application/json",
					'Authorization': `Bearer ${user.token}`
				},

			})
			const json = await response.json()

			if (response.ok) {
				toast.success('Data Updated')
				setTimeout((
					window.location.href = "/metadatalist/" + CategoryName
				), 4000)
			}
		} catch (e) {
			console.log(e
			)
		} finally {
			setLoader(false);
		}

	}
	return (
		<div className="home">
			<div className="workouts">
				<JsonEditor
					data={editObject}
					onChange={data => {
						setEditObject(data)
					}}
					optionsMap={{
						color: [
							{ value: 'red', label: 'Red' },
							{ value: 'blue', label: 'Blue' },
						],
						city: [
							{ value: 'beijing', label: 'Beijing' },
							{ value: 'shanghai', label: 'Shanghai' },
						],
					}}
				/></div>
			<div className="create">
				{loader ? <div className="loader"></div> :
					<button className="button-save" onClick={handleupdate}>Update metadata</button>}</div>
		</div>
	)
}