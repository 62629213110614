import { useState } from "react"
import { useAuthContext } from '../hooks/useAuthContext'
import { toast } from "react-toastify";
import { Spinner } from "react-bootstrap";
const CategoryDetails = ({ category }) => {
  const { user } = useAuthContext()
  const [loader, setLoader] = useState(false)
  const handleDeleteAll = async () => {
    try {
      setLoader(true)
      const response = await fetch(process.env.REACT_APP_BASE_URL + '/api/collections/deleteall', {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${user.token}`
        }
      })
      const json = await response.json()
      setLoader(false)
      if (!response.ok) {
        toast.error("An error occurred");
      }
      if (response.ok) {

        toast.success("Collection deleted successfully");
        setTimeout((window.location.reload()), 2000)

      }
    }
    catch (e) {
      console.log(e)
      toast.error("An error occurred");
    }
    finally {
      setLoader(false)
    }
  }
  return (
    <>
      <div className="workout-details">
        <h4>{category.CategoryName}</h4>
        <p><strong>Description: </strong>{category.Description}</p>
        <p><strong>Public url: </strong><a target='_blank' rel="noreferrer" href={process.env.REACT_APP_BASE_URL + '/0'} >{process.env.REACT_APP_BASE_URL + '/0'} </a ></p>
        {/* <p>{formatDistanceToNow(new Date(category.createdAt), { addSuffix: true })}</p> */}
        <a target='_blank' rel="noreferrer" href={process.env.REACT_APP_BASE_URL + '/api/collections/exportdata/' + category.CollectionId}>Download</a>
        <br />
        <a target='_blank' rel="noreferrer" href={process.env.REACT_APP_BASE_URL + '/api/collections/exportcsv/'}>Export as CSV</a>


        <a href={"/metadatalist/" + category.CategoryName}>
          <span className="material-symbols-outlined" >visibility</span>
        </a>


      </div >
      {loader ? <Spinner variant="primary" animation="border" /> :
        <span className="pointer" onClick={handleDeleteAll}>Delete All</span>
      }
    </>
  )
}

export default CategoryDetails