import React, { useState } from "react";
import { Modal, Button, Spinner } from "react-bootstrap";
import { useAuthContext } from "../../hooks/useAuthContext";
import { toast } from 'react-toastify'
export default function NewMetaData(props) {
	const { user } = useAuthContext()
	const { setAddnewMeta, categoryname, collection_id } = props
	const [uploadedFiles, setUploadedFiles] = useState({})
	const [loader, setLoader] = useState(false)
	const handleClose = () => {
		setAddnewMeta(false)
	}
	const handleFileUpload = async (files) => {
		setLoader(true)
		var obj = {}

		for (let i = 0; i < files.length; i++) {
			var fileReader = new FileReader();
			await fileReader.readAsText(files[i], "UTF-8");
			fileReader.onload = e => {
				let filename = files[i].name.split('.')
				filename = filename[0]
				obj[filename] = JSON.parse(e.target.result)

			};
		}
		setUploadedFiles(obj)
		setLoader(false)

	}
	const handleSubmit = async (e) => {
		e.preventDefault()
		setLoader(true)

		const newdata = { categoryname, collection_id, uploadedFiles }
		try {
			var size = Object.keys(uploadedFiles).length;
			console.log(size)
			const response = await fetch(process.env.REACT_APP_BASE_URL + '/api/collections/create', {
				method: 'POST',
				body: JSON.stringify(newdata),
				headers: {
					'Content-Type': 'application/json',
					'Authorization': `Bearer ${user.token}`
				}
			})
			const json = await response.json()

			if (!response.ok) {
				toast.error(json[0]);
			}
			if (response.ok) {
				setAddnewMeta(false)
				toast.success("Collection created successfully");
				setTimeout((window.location.reload()), 2000)

			}
		}
		catch (e) {
			console.log(e)
			toast.error("An error occurred");
		}
		finally {
			setLoader(false)
		}
	}
	return (
		<Modal show={true} onHide={handleClose}>
			<Modal.Header closeButton>
				<Modal.Title>Add New Metadata</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<span>Collection Name:</span> <span className="font-weight-bold">{categoryname}</span>
				<br />
				<label>Collection json</label>
				<input type="file" id="file" multiple name="file" onChange={e => handleFileUpload(e.target.files)} />
			</Modal.Body>
			<Modal.Footer>
				<Button variant="secondary" onClick={handleClose}>
					Close
				</Button>
				{loader ? <Spinner variant="primary" animation="border" /> :
					<Button variant="primary" onClick={handleSubmit}>
						Upload
					</Button>
				}
			</Modal.Footer>
		</Modal>
	)
}